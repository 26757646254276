import { Grid, Tab, Tabs } from "@material-ui/core";
import classNames from "classnames";
import { differenceInDays, subDays } from "date-fns";
import cloneDeep from "lodash/cloneDeep";
import merge from "lodash/merge";
import queryString from "query-string";
import { useEffect, useMemo, useState } from "react";
import { ACCOUNT_CATEGORY } from "../../commons/enum";
import {
  cashBalanceHistoryChartBaseOptions,
  cashBalanceHistoryChartOptionsMobile,
  lineCustomOptionsOnMobile,
  toCashBalanceChartData,
  transactionsComparator,
  useMobileHeaderTabsStyles,
  useSmallTabsStyles,
} from "../../commons/transactions.common";
import {
  LineChartComponent,
  LineChartMobileWrapper,
  LoadingBackdrop,
  LoadingBackdropContainer,
  PageBody,
  PageBodyContent,
  SimpleTransactionList,
  TransactionsTable,
  WithLoadMore,
} from "../../components";
import HeaderComponent from "../../components/HeaderComponent/header-component";
import ConnectBankModal, {
  DefaultConnectBank,
} from "../../components/connect-bank-modal";
import LinkedAccountCarousel from "../../components/linked-account-carousel";
import LinkedBank from "../../components/linked-bank";
import SharesAndSuperFilterContext from "../../context/shares-super-filter-context";
import DateRangePickerModal from "../../features/transactions/date-range-picker";
import {
  useAccountList,
  useDetailAccountList,
  useIncrementalLimits,
  useIsDesktopSmall,
  useIsMobile,
  useIsNotConnectingToAnyShareOrSuper,
  useIsTablet,
  useLastXDaysFinanceHistory,
  useLinkedBankAccountData,
} from "../../hooks";
import { AwareSuper, CbusSuper, CommSec, Netwealth } from "../../images";
import "../../scss/shares-and-super.scss";
import {
  calculateAccountsTotalBalance,
  checkIsDemoClient,
  getChartBalanceTitle,
} from "../../utils";
import { useTabStyles } from "../../utils/styles-util";
import transactionVideo from "../../videos/transaction.mp4";

import DateFilter, { dateFilterTabs as TABS } from "./date-filter";

const STEP = 10;

const DISPLAYED_ACCOUNT_CATEGORIES = [
  ACCOUNT_CATEGORY.SHARE,
  ACCOUNT_CATEGORY.SUPER,
];

const DEFAULT_LOGOS = [
  { src: AwareSuper, alt: "AwareSuper" },
  { src: CbusSuper, alt: "CbusSuper" },
  { src: Netwealth, alt: "Netwealth", smallPadding: true },
  { src: CommSec, alt: "CommSec", fullWidth: true, largePadding: true },
];

const ShareAndSuperPage = ({ location }) => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const [dateRangeTime, setDateRangeTime] = useState([
    {
      startDate: new Date(),
      endDate: subDays(new Date(), 7),
      key: "selection",
    },
  ]);

  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [dialogState, setDialogState] = useState(false);

  const { account: initialAccountId = "" } = queryString.parse(location.search);
  const isDesktopSmall = useIsDesktopSmall();
  const tabsStyles = useTabStyles();
  const smallTabsStyles = useSmallTabsStyles();
  const mobileHeaderTabsStyles = useMobileHeaderTabsStyles();
  const linkedBankAccountData = useLinkedBankAccountData();
  const isNotConnectingToAnyShareOrSuper = useIsNotConnectingToAnyShareOrSuper();
  const { isLoading: isGettingAccount } = useDetailAccountList();
  const [selectedAccountId, setSelectedAccountId] = useState(initialAccountId);
  const [selectedTab, setSelectedTab] = useState(TABS[0]);
  const { numberOfDays, fromDate, toDate } = selectedTab;
  const getFinanceHistoryParams = useMemo(() => {
    if (fromDate && toDate) {
      return {
        accountId: selectedAccountId,
        numberOfDays,
        from: fromDate,
        to: toDate,
        includeTransactions: true,
      };
    }
    return {
      accountId: selectedAccountId,
      numberOfDays,
      includeTransactions: true,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate, toDate, numberOfDays, selectedAccountId]);
  const { data: financeHistory, isLoading } = useLastXDaysFinanceHistory(
    getFinanceHistoryParams
  );

  const displayedAccounts = useMemo(() => {
    return DISPLAYED_ACCOUNT_CATEGORIES.flatMap(
      (category) => linkedBankAccountData?.[category] || []
    );
  }, [linkedBankAccountData]);
  const totalBalance = calculateAccountsTotalBalance(displayedAccounts);
  const { data: accounts } = useAccountList();
  const selectedAccount = accounts?.data?.find(
    (account) => account.id === selectedAccountId
  );
  const accountType = selectedAccount?.class?.type;

  // balance
  const cashBalanceChartOptions = merge(
    cloneDeep(cashBalanceHistoryChartBaseOptions),
    isTablet ? cashBalanceHistoryChartOptionsMobile : {}
  );

  const cashBalanceHistoryChartData = toCashBalanceChartData(
    financeHistory?.cashBalances
  );

  // transactions
  const transactions = useMemo(
    () =>
      financeHistory?.transactions
        ?.slice()
        .reverse()
        .sort(transactionsComparator) || [],
    [financeHistory?.transactions]
  );

  const {
    limit,
    increment: incrementLimit,
    reset: resetLimit,
  } = useIncrementalLimits(STEP);

  useEffect(() => {
    resetLimit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccountId]);

  const renderIfHasTransactions = (element) => {
    if (transactions?.length > 0) {
      return element;
    }
    return (
      !isLoading && (
        <Grid
          className="transactions-detail-block block"
          item
          xs={12}
          container
          justify="center"
        >
          {`There are no transactions in the last ${numberOfDays} days`}
        </Grid>
      )
    );
  };

  const handleTabChange = (value) => {
    if (value !== "Custom Date Range" || value.includes("-")) {
      setSelectedTab(TABS.find((tab) => tab.title === value));
    } else {
      setDialogState(true);
    }
  };

  useEffect(() => {
    if (displayedAccounts.length) {
      const found = displayedAccounts.find(
        (account) => account.accountId === selectedAccountId
      );
      if (found) {
        setSelectedAccountId(found.accountId);
      } else {
        const firstAccount = displayedAccounts[0];
        setSelectedAccountId(firstAccount.accountId);
      }
    }
  }, [displayedAccounts, linkedBankAccountData, selectedAccountId]);

  const handleClose = () => {
    setDialogState(false);
    if (selectedDateRange) {
      setDateRangeTime(selectedDateRange);
    }
  };

  const handleConfirmDate = () => {
    const dateRange = differenceInDays(
      dateRangeTime[0].endDate,
      dateRangeTime[0].startDate
    );
    setSelectedTab({
      title: "Custom",
      fromDate: dateRangeTime[0].startDate,
      toDate: dateRangeTime[0].endDate,
      numberOfDays: dateRange,
    });
    handleClose();
    setSelectedDateRange(dateRangeTime);
  };

  const mobileView = (
    <Grid container>
      <Grid className="cash-balance-block block" item xs={12}>
        <LineChartMobileWrapper
          chartTitle={getChartBalanceTitle(accountType)}
          subTitle={`(Last ${numberOfDays} days)`}
          titleValue={selectedAccount?.balance}
          chartData={[
            {
              data: cashBalanceHistoryChartData,
              label: "Balance",
              customOptions: lineCustomOptionsOnMobile,
            },
          ]}
        />
        <DateFilter />
      </Grid>
      {renderIfHasTransactions(
        <Grid className="transactions-detail-block block" item xs={12}>
          <div className="block__title">Account transactions</div>
          <LoadingBackdropContainer>
            <LoadingBackdrop isOpen={isLoading} />
            <WithLoadMore
              shown={!!transactions.length && limit < transactions.length}
              onLoadMore={incrementLimit}
            >
              <SimpleTransactionList
                transactions={transactions}
                limit={limit}
              />
            </WithLoadMore>
          </LoadingBackdropContainer>
        </Grid>
      )}
    </Grid>
  );

  const largeView = (
    <Grid container>
      <Grid className="cash-balance-block block" item xs={12}>
        <div className="cash-balance-block__header">
          <div className="cash-balance-block__title">
            {getChartBalanceTitle(accountType)}
          </div>
          <div>
            <Tabs
              value={selectedTab.title}
              onChange={(e, value) => {
                handleTabChange(value);
              }}
              variant="scrollable"
              scrollButtons="auto"
              indicatorColor="secondary"
              textColor="inherit"
              TabIndicatorProps={{ children: <span /> }}
              classes={{
                root: smallTabsStyles.tabs,
                flexContainer: smallTabsStyles.tabsFlexContainer,
                indicator: smallTabsStyles.indicator,
              }}
            >
              {TABS.map((tab) => (
                <Tab
                  key={tab.title}
                  value={tab.title}
                  label={tab.title}
                  classes={{
                    root: smallTabsStyles.tab,
                    selected: smallTabsStyles.selectedTab,
                  }}
                  disabled={
                    checkIsDemoClient() && tab.title === "Custom Date Range"
                  }
                />
              ))}
            </Tabs>
          </div>
        </div>
        <LoadingBackdropContainer>
          <LoadingBackdrop isOpen={isLoading} />
          <LineChartComponent
            lineChartData={cashBalanceHistoryChartData}
            legendDisplay={false}
            customChartOptions={cashBalanceChartOptions}
          />
        </LoadingBackdropContainer>
      </Grid>
      {renderIfHasTransactions(
        <Grid className="transactions-detail-block block" item xs={12}>
          <LoadingBackdropContainer>
            <LoadingBackdrop isOpen={isLoading} />
            <WithLoadMore
              shown={!!transactions.length && limit < transactions.length}
              onLoadMore={incrementLimit}
            >
              <TransactionsTable
                transactions={transactions}
                limit={limit}
                balanceColumnLabel="Portfolio Value"
              />
            </WithLoadMore>
          </LoadingBackdropContainer>
        </Grid>
      )}
    </Grid>
  );

  return (
    <SharesAndSuperFilterContext.Provider
      value={{
        selectedTab,
        setSelectedTab,
        handleConfirmDate,
        handleTabChange,
      }}
    >
      {isGettingAccount && <LoadingBackdrop />}
      <div className="page-container shares-and-super-page">
        <div className="page-content">
          {!isNotConnectingToAnyShareOrSuper && !isDesktopSmall && (
            <LinkedBank
              filteredCategories={DISPLAYED_ACCOUNT_CATEGORIES}
              title="Super & Shares"
              value={totalBalance}
              cursor
              selectedAccountId={selectedAccountId}
              onSelectedAccountIdChange={setSelectedAccountId}
            />
          )}

          <PageBody>
            {isNotConnectingToAnyShareOrSuper ? (
              <>
                {!isTablet && <HeaderComponent />}
                <ConnectBankModal
                  videoBackground={transactionVideo}
                  callToActionButton={
                    <DefaultConnectBank
                      title="Connect Accounts"
                      buttonTitle="Connect Account"
                      logos={DEFAULT_LOGOS}
                    />
                  }
                />
              </>
            ) : (
              <>
                {!isTablet && (
                  <HeaderComponent
                    headerContent={
                      <Tabs
                        value={selectedTab.title}
                        onChange={(event, value) => {
                          handleTabChange(value);
                        }}
                        indicatorColor="secondary"
                        textColor="inherit"
                        variant="scrollable"
                        scrollButtons="auto"
                        TabIndicatorProps={{ children: <span /> }}
                        classes={{
                          root: tabsStyles.tabs,
                          flexContainer: tabsStyles.tabsFlexContainer,
                          indicator: classNames(
                            tabsStyles.indicator,
                            mobileHeaderTabsStyles.indicator
                          ),
                        }}
                      >
                        {TABS.map((tab) => (
                          <Tab
                            key={tab.title}
                            value={tab.title}
                            label={tab.title}
                            classes={{
                              root: tabsStyles.tab,
                              selected: tabsStyles.selectedTab,
                            }}
                          />
                        ))}
                      </Tabs>
                    }
                  />
                )}
                <PageBodyContent>
                  {isDesktopSmall && (
                    <LinkedAccountCarousel
                      filteredCategories={DISPLAYED_ACCOUNT_CATEGORIES}
                      selectedAccountId={selectedAccountId}
                      onSelectedAccountIdChange={setSelectedAccountId}
                    />
                  )}
                  {isMobile ? mobileView : largeView}
                  <DateRangePickerModal
                    handleSetDateRange={setDateRangeTime}
                    dateRangeTime={dateRangeTime}
                    handleCloseDialog={handleClose}
                    dialogState={dialogState}
                    handleConfirmDate={handleConfirmDate}
                  />
                </PageBodyContent>
              </>
            )}
          </PageBody>
        </div>
      </div>
    </SharesAndSuperFilterContext.Provider>
  );
};

export default ShareAndSuperPage;
