import React, { useContext } from "react";
import { Grid } from "@material-ui/core";
import SharesAndSuperFilterContext from "../../context/shares-super-filter-context";
import MobileTabs from "../../components/mobile-tab";
import { checkIsDemoClient } from "../../utils";

export const dateFilterTabs = [
  {
    title: "7 Days",
    value: "7 Days",
    numberOfDays: 7,
  },
  {
    title: "14 Days",
    value: "14 Days",
    numberOfDays: 14,
  },
  {
    title: "30 Days",
    value: "30 Days",
    numberOfDays: 30,
  },
  {
    title: "All",
    value: "All",
    numberOfDays: 730,
  },
  {
    title: "Custom Date Range",
    value: "Custom Date Range",
  },
];

const DateFilter = () => {
  const { selectedTab, handleTabChange } = useContext(
    SharesAndSuperFilterContext
  );
  return (
    <Grid
      justify="center"
      style={{ display: "flex", marginTop: 8, width: "100%" }}
    >
      <MobileTabs
        value={selectedTab.value}
        onChange={(_, value) => handleTabChange(value)}
        tabs={dateFilterTabs}
        disabledTab={(tab) => {
          return checkIsDemoClient() && tab.value === "Custom Date Range";
        }}
      />
    </Grid>
  );
};

export default DateFilter;
